/* eslint-disable @typescript-eslint/explicit-function-return-type */ // Disabled to allow dynamic imports
import { authGuard } from '@auth0/auth0-vue'
import { createRouter, createWebHistory } from 'vue-router'

import type BuildingId from '@/types/BuildingId'
import type OrganizationId from '@/types/OrganizationId'

export enum layout {
  overflow = 'overflow',
  noOverflow = 'noOverflow',
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'rootView',
      component: () => import('@/views/TheRootView/TheRootView.vue'),
      beforeEnter: authGuard,
      meta: {
        title: 'Hearsee',
        layout: layout.noOverflow,
      },
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('@/views/TheForbiddenView/TheForbiddenView.vue'),
      meta: {
        title: 'Forbidden',
        layout: layout.noOverflow,
      },
    },
    {
      path: '/organizations/:organizationId',
      name: 'organizationView',
      component: () => import('@/views/TheOrganizationView/TheOrganizationView.vue'),
      props: true,
      beforeEnter: authGuard,
      meta: {
        title: 'Organization',
        layout: layout.noOverflow,
      },
    },
    {
      path: '/organizations/:organizationId/buildings/:buildingMapId',
      name: 'theMappingView',
      component: () => import('@/views/TheMappingView/TheMappingView.vue'),
      props: (route) => ({
        buildingMapId: route.params.buildingMapId as BuildingId,
        organizationId: route.params.organizationId as OrganizationId,
      }),
      beforeEnter: authGuard,
      meta: {
        title: 'Mapping',
        layout: layout.noOverflow,
      },
    },
    {
      path: '/api-docs',
      component: () => import('@/views/TheSwaggerUIView.vue'),
      beforeEnter: authGuard,
      meta: {
        layout: layout.overflow,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} | Hearsee` : 'Hearsee'
  next()
})

export default router
