<script setup lang="ts">
import { ButtonSize, ButtonStyle } from '@/models/Button'

export interface Props {
  disabled?: boolean
  size?: ButtonSize
  buttonStyle?: ButtonStyle
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  size: ButtonSize.MEDIUM,
  buttonStyle: ButtonStyle.FILLED,
})

const emit = defineEmits<{ click: [value: Event] }>()

function click(event: Event): void {
  if (!props.disabled) {
    emit('click', event)
  }
}
</script>

<template>
  <button
    class="app-button"
    :class="[buttonStyle, size]"
    :aria-disabled="disabled"
    @click.prevent="click"
  >
    <!-- @slot SVG icon -->
    <slot name="icon"></slot>

    <!-- @slot The button label -->
    <slot name="default"></slot>
  </button>
</template>

<style lang="scss" scoped>
.app-button {
  display: flex;
  gap: var(--spacing-minimal);
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs) var(--spacing-regular);
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: var(--corner-radius-xs);

  @extend %font-title-medium;

  &[aria-disabled='true'] {
    color: #2c2c2c;
    cursor: initial;
  }
}

:deep(svg) {
  width: 1.125rem;
  height: 1.125rem;
  padding: 0.1875rem;
}

.small {
  @extend %font-title-small;

  height: 26px;
  padding: var(--spacing-minimal) var(--spacing-xs);
}

.medium {
  @extend %font-title-extra-small;
}

.large {
  @extend %font-title-medium;

  padding: var(--spacing-xs) var(--spacing-regular);
}

.filled {
  color: var(--on-primary);
  background: var(--button-primary);

  &.medium {
    padding: var(--spacing-minimal) var(--spacing-xs);
  }

  :deep(svg) {
    fill: var(--on-primary);
  }

  &:hover {
    background: var(--button-primary-hovered);
  }

  &:active {
    background: var(--button-primary-pressed);
  }

  &[aria-disabled='true'] {
    background: var(--button-primary-disabled);
  }
}

.outline {
  color: var(--button-primary);
  background: none;
  border: 1px solid var(--button-primary);

  &:hover {
    background: var(--state-primary-opacity-hover);
  }

  &:active {
    background: var(--state-primary-opacity-pressed);
  }

  &:focus-visible {
    border: 1px solid var(--button-primary);
  }

  &[aria-disabled='true'] {
    color: #2c2c2c;
    pointer-events: none;

    :deep(svg) {
      fill: var(--button-primary-disabled);
    }
  }

  :deep(svg) {
    fill: var(--button-primary);
  }
}

.text {
  padding: var(--spacing-xs) 0;
  color: var(--button-primary);
  text-align: center;
  background: none;
  border: 1px solid transparent;

  @extend %font-title-medium;

  &:hover {
    background: var(--state-primary-opacity-hover);
  }

  &:active {
    background: var(--state-primary-opacity-pressed);
  }

  &:focus-visible {
    border: 1px solid var(--button-primary);
  }

  &[aria-disabled='true'] {
    color: #2c2c2c;
    pointer-events: none;

    :deep(svg) {
      fill: var(--button-primary-disabled);
    }
  }

  :deep(svg) {
    fill: var(--button-primary);
  }
}
</style>
