import { createAuth0 } from '@auth0/auth0-vue'

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
    redirect_uri: window.location.origin,
    scope: 'openid profile email offline_access',
  },
})
