<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'

import AuthenticationService from './api/AuthenticationService'
import { auth0 } from './auth0'
import AppHeader from './components/TheHeader/TheHeader.vue'
import { layout } from './router'

const { isAuthenticated } = auth0

const route = useRoute()

function login(): void {
  AuthenticationService.login()
}

function logout(): void {
  AuthenticationService.logout()
}
</script>

<template>
  <AppHeader
    :is-authenticated="isAuthenticated"
    @login="login"
    @logout="logout"
  ></AppHeader>
  <main
    class="content"
    :class="{ 'layout-overflow': route.meta.layout == layout.overflow }"
  >
    <RouterView />
  </main>
</template>

<style scoped lang="scss">
.top {
  top: 0;
  right: 0;
  left: 0;
  flex: 0 0 auto;
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  &:not(.layout-overflow) {
    overflow: hidden;
  }
}
</style>
