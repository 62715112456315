<script setup lang="ts">
import HearseeLogo from '@/assets/images/logo.svg'
import AuthButton from '@/components/ui/AuthButton/AuthButton.vue'

export interface Props {
  isAuthenticated: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (login: 'login'): void
  (logout: 'logout'): void
}>()
</script>

<template>
  <header>
    <component
      :is="HearseeLogo"
      alt="Hearsee"
    />
    <div class="controls">
      <slot name="controls"></slot>
      <div class="profile-section">
        <AuthButton
          :is-authenticated="props.isAuthenticated"
          @login="emit('login')"
          @logout="emit('logout')"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-xs) var(--spacing-regular);
}
</style>
