enum ButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

enum ButtonStyle {
  FILLED = 'filled',
  TEXT = 'text',
  OUTLINE = 'outline',
}

export { ButtonSize, ButtonStyle }
