import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from './App.vue'
import { auth0 } from './auth0'
import router from './router'
import './assets/_variables.scss'
import Observability from './utils/Observability'

const app = createApp(App)

if (!import.meta.env.DEV) {
  Observability.setup(app, router)
}

app.use(createPinia())
app.use(router)
app.use(auth0)

app.mount('#app')
